jQuery(document).ready(function ($) {

    $('.checkbox_block').click(function () {
        var ckeckbox = $(this).find('.fa');
        var inputbox = $(this).find('input');
        if ($(ckeckbox).hasClass('fa-square-o')) {
            $(ckeckbox).removeClass('fa-square-o').addClass('fa-check-square-o');
            $(inputbox).val($(inputbox).data('value'));
        } else {
            $(ckeckbox).removeClass('fa-check-square-o').addClass('fa-square-o');
            $(inputbox).val('');
        }
    });

    $('.table .actions a').popover({
        placement: 'auto',
        trigger: 'hover',
        delay: {
            show: 500,
            hide: 100
        },
        container: 'body',
        html: true
    });

    window.initPopovered = function () {
        $('.popovered').each(function (i, e) {
            var trigger = 'click';
            if ($(this).data('trigger') != undefined) {
                trigger = $(this).data('trigger');
            }
            var placement = 'auto';
            if ($(this).data('placement') != undefined) {
                placement = $(this).data('placement');
            }
            $(e).popover({
                placement: placement,
                trigger: trigger,
                container: 'body',
                html: true
            });

        });
    };

    initPopovered();
    /*
     $('.popovered').popover({
     trigger: 'hover',
     container: 'body',
     html: true
     });
     */

    $.fn.datetimepicker.dates['ru'] = {
        days: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"],
        daysShort: ["Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Суб", "Вск"],
        daysMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
        months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
        monthsShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
        today: "Сегодня",
        suffix: [],
        meridiem: []
    };


    $('.editable').editable({
        datetimepicker: {
            weekStart: 1,
            language: 'ru',
            autoclose: true
        },
        ajaxOptions: {
            type: 'post',
            dataType: 'json'
        },
        url: '/',
        params: function (params) {
            params.table = $(this).data('table');
            params.method = $(this).data('method');
            params.action = $(this).data('action');
            return params;
        },
        success: function (response, newValue) {
            if (!response.success) return response.message;
            if (response.message == 'reload') location.reload();
        }
    });

    window.selectTemplate = function (e) {
        var val = $(e).val();
        var tinymce_id = $(e).closest('.modal').find('.tinymce').attr('id');
        //var textarea = $(modal).find('.mce-container').attr('id');
        tinymce.get(tinymce_id).setContent(val);
        tinymce.triggerSave();
    };

    $('.modal .submit').click(function () {
        if ($(this).data('clicked') === true) {
            return;
        }
        $(this).data('clicked', true);
        $(this).find('.fa-spinner').show();
        var Modal = $(this).closest('.modal');
        var Form = Modal.find('form');
        var Data = new FormData(Form[0]);
        //$(Modal).find('.alert').html('');
        $(Modal).find('.alert').removeClass('alert-success').removeClass('alert-danger');
        $(Modal).find('.alert').slideUp();
        if ($(this).data('option') !== undefined) {
            Data.append('option', $(this).data('option'));
        }
        $.ajax({
            url: Form.attr('action'),
            type: Form.attr('method'),
            dataType: 'json',
            data: Data,
            processData: false,
            contentType: false,
            context: this,
            success: function (data) {
                if (data.status === 'success') {
                    $(this).find('.fa-spinner').hide();
                    if (data.reload) {
                        location.reload();
                    } else if (data.location !== undefined) {
                        window.location.href = data.location;
                    } else {
                        $(Modal).find('.mb_wrapper').slideUp();
                        $(Modal).find('.submit').hide();
                        $(Modal).find('.hide').show();
                        $(Modal).find('.alert').addClass('alert-success');
                        $(Modal).find('.alert').html(data.message);
                        $(Modal).find('.alert').slideDown();
                    }
                    $(Modal).data('status', data.status);
                } else if (data.status === 'error') {
                    if (data.reload) {
                        location.reload();
                    } else {
                        $(Modal).find('.alert').addClass('alert-danger');
                        $(Modal).find('.alert').html(data.message);
                        $(Modal).find('.alert').slideDown();
                    }
                }
            }
        });
    });

    $('#change_password').on('hidden.bs.modal', function () {
        if ($(this).data('status') == 'success') {
            location.reload();
        }
    });

    $('.ajax_send').on('submit', function (e) {
        e.preventDefault();
        var $that = $(this);
        var form_data = $(this).serialize();

    });

    $.fn.select2.defaults.set("theme", "bootstrap");

    window.select2Gen = function (elem) {
        if (elem == '' || elem == undefined) {
            elem = '.select2';
        }
        $(elem).each(function (i, e) {
            var options = {};
            if ($(this).data('search')) {
            } else {
                options["minimumResultsForSearch"] = Infinity;
            }
            $(this).select2(options);
        });
    };

    select2Gen();

    window.update = function (from, to) {
        $(to).html($(from).val());
    };

    $('.href').click(function (e) {
        e.preventDefault();
        if ($(this).data('target') !== '_blank') {
            window.location.href = $(this).data('href');
        } else {
            window.open($(this).data('href'), '_blank');
        }
    });

    $('#recharge').click(function () {
        var input = $(this).closest('.input-group').find('input');
        var value = input.val();
        console.log(value);
        if (value != undefined && value != '') {
            window.location.href = '/index.php/?location=1&action=recharge&value=' + value;
        } else {
            popoverShowHide(input, $(input).data('error'));
        }
    });

    window.serialize = function (data) {
        $.each(data, function (i, e) {
            console.log(i, e);
        });
    };

    window.sendAjax = function (params) {
        return $.ajax({
            url: '/',
            dataType: 'json',
            type: 'post',
            data: params
        });
    };

    $('.ajax_send.toggle, .ajax_send.simple_text, .ajax_send.file').change(function () {
        sendSimpleAjax(this);
    });

    $('.ajax_send.dropdown_link, .ajax_send.btn').click(function () {
        sendSimpleAjax(this);
    });

    function sendSimpleAjax(element) {
        $(element).closest('div').popover('destroy');
        var popoverElement = $(element).closest('div.popover_element');
        var Data = new FormData();
        Data.append('action', $(element).data('action'));
        Data.append('name', $(element).data('name'));
        if ($(element).hasClass('toggle')) {
            if ($(element).prop('checked')) {
                Data.append('value', 1);
            } else {
                Data.append('value', 0);
            }
        } else if ($(element).hasClass('dropdown_link')) {
            Data.append('value', $(element).data('value'));
        } else if ($(element).hasClass('simple_text')) {
            Data.append('value', $(element).val());
        } else if ($(element).hasClass('file')) {
            $.each(element.files, function (key, value) {
                Data.append(key, value);
            });
        }
        $.ajax({
            url: '/',
            dataType: 'json',
            context: $(element),
            type: 'post',
            data: Data,
            processData: false,
            contentType: false,
            success: function (data) {
                if (data['status'] === 'success' || data['status'] === 'error') {
                    if (data['status'] === 'success') {
                        var message = '<div class="green"><em class="fa fa-check" aria-hidden="true"></em> ' + data['message'] + '</div>';
                        if ($(element).hasClass('dropdown_link')) {
                            $(element).closest('div.dropdown').children('a').html($(element).html());
                        }
                    } else {
                        var message = '<div class="red"><em class="fa fa-times" aria-hidden="true"></em> ' + data['message'] + '</div>';
                        $(element).data('error', true);
                    }
                    popoverShowHide(popoverElement, message);
                } else {
                    popoverShowHide(popoverElement, '<div class="red"><em class="fa fa-times" aria-hidden="true"></em> ' + $('#json-data').data('send-fail') + '</div>');
                    $(element).data('error', true);
                }
            },
            error: function () {
                popoverShowHide(popoverElement, '<div class="red"><em class="fa fa-times" aria-hidden="true"></em> ' + $('#json-data').data('send-fail') + '</div>');
                $(element).data('error', true);
            }
        });
    }

    window.popoverShowHide = function (element, message) {
        var popover = $(element).popover({
            content: message,
            trigger: 'manual',
            html: true,
            container: 'body',
            placement: 'auto left'
        }).popover('show');
        setTimeout(function () {
            $(popover).popover('destroy');
        }, 10000);
    };

    window.closePopover = function (popover) {
        $(popover).popover('destroy');
    };

    $('*[data-popover_type]').hover(function () {
        var Data = {};
        Data.action = "get_popover";
        Data.type = $(this).data('popover_type');
        var Content = $.ajax({
            url: '/',
            async: false,
            data: Data,
            method: 'post'
        }).responseText;
        $(this).popover({
            content: Content,
            title: $(this).data('popover_title'),
            trigger: 'manual',
            html: true,
            container: 'body',
            placement: 'auto right'
        }).popover('show');
    }, function () {
        $(this).popover('destroy');
    });

    $('.statAjax').click(function (e) {
        e.preventDefault();
        var new_title = $(this).html();
        var Data = {
            action: $(this).data('action'),
            value: $(this).data('value')
        };
        $.ajax({
            url: '/',
            dataType: 'json',
            type: 'post',
            context: $(this),
            data: Data,
            success: function (data) {
                if (data.status == 'success') {
                    $($(this).data('target')).html(data.message);
                    $($(this).data('target2')).html(new_title);
                }
            }
        });
    });

    $('a[data-action="get_site_visit_chart"]').click(function (e) {
        e.preventDefault();
        var new_title = $(this).html();
        var Data = {
            action: $(this).data('action'),
            value: $(this).data('value')
        };
        $.ajax({
            url: '/',
            dataType: 'json',
            type: 'post',
            context: $(this),
            data: Data,
            success: function (data) {
                if (data.status == 'success') {
                    $('#site_name_visit_chart').html(new_title);
                    //console.log(data.message['visits_json']  );
                    drawChartVisit(data.message['visits']);
                }
            }
        });
    });

    $('a[data-action="get_site_size_chart"]').click(function (e) {
        e.preventDefault();
        var new_title = $(this).html();
        var Data = {
            action: $(this).data('action'),
            value: $(this).data('value')
        };
        $.ajax({
            url: '/',
            dataType: 'json',
            type: 'post',
            context: $(this),
            data: Data,
            success: function (data) {
                if (data.status == 'success') {
                    $('#site_name_size_chart').html(new_title);
                    //console.log(data.message['visits_json']  );
                    drawChartSize(data.message.size, data.message.limit);
                }
            }
        });
    });

    $('a[href="#modal_change_tarif"]').click(function () {
        var site_id = $(this).data('site_id');
        var site_name = $(this).data('site_name');
        $('#modal_change_tarif').find('h4 > span').html(site_name);
        $('#modal_change_tarif').find('input[name="site_id"]').val(site_id);
    });

    $('.confirm').click(function (e) {
        e.preventDefault();
        var href = $(this).attr('href');
        console.log(href);
        var message = $(this).data('message');
        $('#confirm').find('.btn-success').attr('href', href);
        $('#confirm').find('.modal-body').html(message);
        $('#confirm').modal('show');
    });

    $('#sidebar-toggler > div').click(function () {
        if ($('#sidebar').closest('.row').hasClass('min')) {
            var value = '';
        } else {
            var value = 'min';
        }
        saveUserOption('sb_toggle', value, this);
        $('#sidebar').closest('.row').toggleClass('min');
    });

    function saveUserOption(name, value, context) {
        var Data = {
            action: 'save_user_option',
            name: name,
            value: value
        };
        $.ajax({
            url: '/',
            dataType: 'json',
            type: 'post',
            context: $(context),
            data: Data,
            success: function (data) {
                if (data.status == 'success') {
                    $(this).attr('data-success', data.message);
                }
            }
        });
    }

    $('.file_wrapper input[type=file]').change(function () {
        if ($(this).val() != undefined && $(this).val() != '') {
            $(this).closest('.file_wrapper').find('label').html($(this).val());
        } else {
            $(this).closest('.file_wrapper').find('label').html($(this).closest('.file_wrapper').find('label').data('empty'));
        }
        console.log($(this).val());
    });

    $('#forgot_password').click(function (e) {
        e.preventDefault();
        $('div[data-block="login_group"]').slideUp();
        $('div[data-block="remember_password_group"]').slideDown();
    });

    $('#login_form_link').click(function (e) {
        e.preventDefault();
        $('div[data-block="login_group"]').slideDown();
        $('div[data-block="remember_password_group"]').slideUp();
    });

    $('#remember_password_button').click(function (e) {
        e.preventDefault();
        $('div[data-block="remember_password_answer"]').html('').slideUp();
        var Data = {
            action: 'send_remember_email',
            email: $('input[name="email"]').val()
        };
        $.ajax({
            url: '/',
            dataType: 'json',
            type: 'post',
            data: Data,
            success: function (data) {
                if (data.status == 'success') {
                    $('div[data-block="remember_password_form"]').slideUp();
                    $('div[data-block="remember_password_answer"]').html('<div class="alert alert-success">' + data.message + '</div>').slideDown();
                } else if (data.status == 'error') {
                    $('div[data-block="remember_password_answer"]').html('<div class="alert alert-danger">' + data.message + '</div>').slideDown();
                }
                console.log(data);
            }
        });
    });

    window.showMessage = function (data, reset) {
        var modal = $('#message_modal');
        var modal_body = $(modal).find('.modal-body');
        if (data[0].content == undefined) return;
        if (reset === true) {
            $(modal_body).html();
        }
        $(data).each(function (item, element) {
            var html = '<dl class="alert dl-horizontal alert-' + element.type + '">';
            html += '<dt>' + element.title + '</dt>';
            html += '<dd>' + element.content + '</dd>';
            html += '</dl>';
            $(modal_body).append(html);
        });
        $(modal).modal('show');
    };

    var onload = function () {
        var data = $('#message_modal').data('message');
        if (data != undefined && data != '') {
            showMessage(data);
        }
    };

    onload();

    window.reload = function () {
        console.log(123);
        location.reload();
    };

    $(document).on('focusin', function (e) {
        if ($(event.target).closest(".mce-window").length) {
            e.stopImmediatePropagation();
        }
    });

    $('.draggable').draggable({
        handle: '.modal-header',
        appendTo: 'window'
    });

    window.ajax_modal = function (Data) {
        $('#ajax_modal').modal('show');
        $('#ajax_modal .modal-content').html('');
        $('#ajax_modal .wait_logo').addClass('show');
        $.ajax({
            url: '/',
            dataType: 'html',
            type: 'post',
            data: Data,
            success: function (data) {
                $('#ajax_modal .wait_logo').removeClass('show');
                $('#ajax_modal .modal-content').html(data);
                setTimeout(function () {
                    $('#ajax_modal .modal-dialog').slideDown();
                }, 100);

            }
        });
    };

    $('#ajax_modal').on('hide.bs.modal', function () {
        $('#ajax_modal .modal-dialog').removeClass('modal-lg');
        $('#ajax_modal .wait_logo').addClass('hide');
        $('#ajax_modal .modal-dialog').slideUp();
    });

    $('.ajax_modal').click(function (e) {
        e.preventDefault();
        var Data = $(this).data();
        var addClass = $(this).attr('data-addclass');

        //Добавление класса к модали (например для большой модали)
        if (addClass != undefined) {
            $('#ajax_modal .modal-dialog').addClass(addClass);
            delete Data.addclass;
        }
        console.log(Data);
        ajax_modal(Data);
    });

    window.ajaxModal = function (e) {
        var Data = $(e).data();
        ajax_modal(Data);
    }

    $('.editable_block').click(function (e) {
        var id = $(this).attr('id');
        tinymce.init({
            selector: '#' + id,
            inline: true
        });
    });

    window.tinymceInlineInit = function (id) {
        tinyInit(id);
    };

});